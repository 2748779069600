import React, { useEffect, useState } from "react";
import { StyledNav } from "./style";
import EgyptLogo from "../../../../assests/img/logo-egypt.png";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleNavbar } from "../../../../redux/orderSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const showNavbar = useSelector((state) => state.order.showNavbar);

  return (
    <StyledNav>
      <nav className="bg-light navbar navbar-expand-lg navbar-light">
        <div
        // className={
        //   location.pathname === "/" && scrollPosition > 475 ? "nav-width" : ""
        // }
        >
          <div className="navbar-header">
            <Link className="navbar-brand" to="#">
              <img alt="Brand" src={EgyptLogo} />
            </Link>
          </div>
          <button
            className={
              showNavbar
                ? "navbar-toggler nav-toggler-align"
                : "navbar-toggler collapsed nav-toggler-align"
            }
            // id="navbar-toggle"
            type="button"
            data-bs-toggle={showNavbar ? "collapse show" : "collapse"}
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={showNavbar}
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              onClick={() => dispatch(toggleNavbar())}
            ></span>
          </button>
          <div
            className={
              showNavbar
                ? "collapse navbar-collapse show-nav nav show"
                : "collapse navbar-collapse nav hide"
            }
            id="navbarNav main-nav"
          >
            <ul className="navbar-nav">
              <li
                className={
                  location.pathname === "/" ? "nav-item active" : "nav-item"
                }
              >
                <Link
                  aria-current="page"
                  className="nav-link"
                  to="https://egypt-portal.site/index.php"
                >
                  Home
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/apply-now"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  to="https://egypt-portal.site/step_1.php"
                >
                  Apply Now
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/additional-benefits"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  to="https://egypt-portal.site/additional_benefits.php"
                >
                  Additional Benefits
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/terms-conditions"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  to="https://egypt-portal.site/terms_condition.php"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/privacy-policy"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  to="https://egypt-portal.site/privacy_policy.php"
                >
                  Privacy Policy
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/refund-policy"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  to="https://egypt-portal.site/refund_policy.php"
                >
                  Refund Policy
                </Link>
              </li>

              <li
                className={
                  location.pathname === "/refund-policy"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  to="https://egypt-portal.site/faq.php"
                >
                  FAQ
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/contact-us"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  to="https://egypt-portal.site/contact_us.php"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </StyledNav>
  );
};

export default Navbar;
