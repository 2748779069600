import styled from "styled-components";

export const StyledContainer = styled.div``;

export const StyledChangepswdBlock = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  overflow: hidden;
  padding: 0px 25px 25px 25px;
  margin-bottom: 30px;
  font-size: 18px;
  @media only screen and (max-width: 991px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0px 2px 15px 2px;
  }

  & h2 {
    background: #333;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 25px;
    margin: 0px -25px 25px;
  }

  & p {
    color: red;
    font-weight: 500;
  }

  .table-block table {
    width: 100%;
    table-layout: fixed;
  }
  .table-block table tr:first-child td {
    border-top: 0px;
  }
  .order-dtl-block .table-block table tr:first-child td {
    border-top: 0;
  }

  .order-dtl-block table td {
    font-size: 18px;
  }
  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
      padding: 5px;
    }
  }
  table td {
    word-wrap: break-word;
  }

  table td {
    font-size: 18px;

    @media only screen and (max-width: 991px) {
      font-size: 16px;
    }
  }
  table td:nth-child(2) {
    font-weight: 600;
  }
  .form-control {
    border: 1px solid #e1e1e1;
    box-shadow: none;
    font-size: 16px;
    color: #111;
    height: 40px;
    background: #fff;
  }
  .file-upload {
    border: 1px solid #e1e1e1;
    box-shadow: none;
    font-size: 18px;
    color: #111;
    height: 40px;
    background: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0px 5px;
  }
  .file-upload input {
    width: 0px;
    height: 0px;
    position: absolute;
  }
  .file-upload span {
    background: #18f;
    color: #fff;
    border-radius: 2px;
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
  }
  textarea.form-control {
    min-height: 88px;
    height: auto;
    margin-bottom: 15px;
  }
  .checkbox label {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .blue-btn {
    margin-top: 5px;
  }

  .blue-btn {
    background-color: #832a34;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: inline-block;

    &:focus,
    &:hover {
      background-color: #832a34;
      color: #fff;
      -webkit-text-decoration: none;
      text-decoration: none;
    }
    @media only screen and (max-width: 991px) {
      font-size: 15px;
      padding: 12px 10px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 15px;
      padding: 10px 15px;
    }
  }

  & hr {
    width: 100%;
    size: 10px;
  }

  @media only screen and (max-width: 767px) {
    .col-md-6.pdl-0 {
      padding-left: 15px;
    }
  }
`;
