import React from "react";
import { TransactionContainer } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  defencePack,
  getOrderDetailsByOrderId,
} from "../../../redux/orderSlice";

const initialValues = {
  transactionId: "",
  merchantName: "",
};

const transactionSchema = yup.object({
  transactionId: yup.string().required("Please enter your Transaction Id"),
  merchantName: yup.string().required("Please enter your Merchant Name"),
});

const TransactionModal = ({ SetShowTransaction }) => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: transactionSchema,
    onSubmit: (values) => {
      console.log('values: ', values);
      let data = {
        transactionId: values.transactionId,
        merchantName: values.merchantName,
        orderId: OrderDetails?.order_id,
      };
      dispatch(defencePack(data))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            SetShowTransaction(false);
            dispatch(getOrderDetailsByOrderId(orderId));
          }
        });
    },
  });

  return (
    <TransactionContainer>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Enter Transaction ID:</label>
          <br />
          <input
            type="text"
            placeholder="Transaction ID"
            name="transactionId"
            value={values.transactionId}
            className="form-control"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p>{errors.transactionId}</p>
        </div>

        <div className="form-group">
          <label>Enter Merchant Name:</label>
          <input
            type="text"
            placeholder="Merchant Name"
            name="merchantName"
            value={values.merchantName}
            className="form-control"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p>{errors.merchantName}</p>
        </div>
        <button variant="btn btn-dark" type="submit">
          Submit
        </button>
      </form>
    </TransactionContainer>
  );
};

export default TransactionModal;
