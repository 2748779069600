import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import AdminLayout from "./containers/Layout/AdminLayout/AdminLayout";
import SignupLayout from "./containers/Layout/SignupLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminTrackLayout from "./containers/Layout/AdminTrackLayout/AdminTrackLayout";

const App = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
      <Route path="/*" element={<AdminTrackLayout />} />
      <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/" element={<SignupLayout />} />
      </Routes>
    </>
  );
};

export default App;
