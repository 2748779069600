import React from "react";
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
import { encryptVal } from "../../../utility/utility";
import AdminLogo from "../../../assests/img/logo-egypt.png";

const EmailContent = () => {
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  return (
    <EmailContainer>
      <StyleLogo>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          styel={{ padding: " -37px", width: "130px" }}
        />
      </StyleLogo>
      <hr />
      <p>
        <b>{`Dear Customer: ${OrderDetails?.applicantInfo[0]?.first_name} ${OrderDetails?.applicantInfo[0]?.sur_name} `}</b>
      </p>
      <p>We would like to inform you that your E-VISA application has been successfully approved.</p>
      <p><b>A E-VISA is automatically linked to your passport.</b></p>
      <p>
        To download your E-VISA confirmation
      </p>
      <p>Please click on the link below</p>
      <p><b>Username</b>: <Link to="inquiries@egypt-portal.site">{OrderDetails?.applicantInfo[0]?.applicant_email ? OrderDetails?.applicantInfo[0]?.applicant_email : ""} </Link></p>
      <p><b>Order ID</b> : {OrderDetails?.order_id} </p>

      <Link to={`https://admin-eg.online/track-order/${OrderDetails?.order_id ? encryptVal(OrderDetails?.order_id) : ""}`}  target="_black" className="apply-btn">
        Click Here
      </Link>
      <br/>
      <br/>
      <p><b>E-VISA Validity</b></p>
      <p>The E-VISA is valid until the Expiration date noted in the document.</p>
      <p>It is recommended, but not required, that you take a printed or digital copy of your E-VISA with you .</p>
      <p>Please make sure you check the official requirements and health regulations before traveling to your destination as travel restrictions may be applied in some cases.</p>
      <p>Please contact us with any questions you may have regarding your E-VISA.</p>


      <p>
      Should you have any inquiries, please contact us through our
        <Link to="#"> contact form </Link>or via email at <Link to="inquiries@egypt-portal.site">
          inquiries@egypt-portal.site
        </Link>
        indicating your order ID.
      </p>

      <p className="flex-class">
        <b>Customer Service Number:</b> +1 (407) 955 - 9631
      </p>
      <p className="flex-class">
        <b>Order ID:</b> {OrderDetails?.order_id}
      </p>
      <br /> 

      <p>
        <b>Important Notice:</b>
      </p>
      <p>
        {" "}
        If you are not satisfied with the service you have received or wish to
        file a complaint, please contact us at <Link to="mailto:inquiries@egypt-portal.site">
          inquiries@egypt-portal.site{" "}
        </Link>{" "}
        , where we will be happy to assist you or alternatively click on one of
        the links below for more information.
      </p>
      <p>Thank you for using our services.</p>
      <p> Regards,</p>
      <p><b>Processing Department</b></p>
      <p>
        <Link to="https://egypt-portal.site/terms_condition.php">Terms</Link> |{" "}
        <Link to="https://egypt-portal.site/privacy_policy.php">Privacy</Link> |{" "}
        <Link to="https://egypt-portal.site/contact_us.php">Contact</Link> |{" "}
        <Link to="https://egypt-portal.site/refund_policy.php">Refund</Link>
      </p>
    </EmailContainer>
  );
};

export default EmailContent;
